
import { useUiStore } from '@/stores/ui'

export default {
  name: 'PopupAvailabilityGoodsSpecified',
  setup() {
    const uiStore = useUiStore()
    return { uiStore }
  },
  data() {
    return {
      width: 560,
    }
  },

  computed: {
    availability_goods_specified: {
      get() {
        return this.uiStore.availabilityGoodsSpecified
      },
      set(value) {
        this.uiStore.availabilityGoodsSpecified = value
      },
    },
  },
  methods: {
    closeModal() {
      this.uiStore.availabilityGoodsSpecified = false
    },
  },
}
